<template>
  <div class="max-w-3xl mx-auto p-4 container">
    <h1 class="text-2xl font-bold text-center">Assuntos Pendentes</h1>
    <button class="btn" @click="showModal = true">Adicionar Assunto</button>
    <b-modal
      v-model="showModal"
      title="Adicionar Assunto Pendente"
      @ok="addTicket"
    >
      <!-- assunto pendente -->
      <b-form-group label="Assunto" label-for="subject">
        <b-form-input
          id="subject"
          v-model="subject"
          placeholder="Indique o assunto"
          required
        ></b-form-input>
      </b-form-group>

      <!-- descriçao -->
      <b-form-group label="Descriçao" label-for="description">
        <b-form-input
          id="description"
          v-model="description"
          placeholder="Escreva uma descriçao"
          required
        ></b-form-input>
      </b-form-group>
    </b-modal>
    <div
      v-for="ticket in tickets"
      :key="ticket.id"
      class="bg-white p-4 rounded-lg shadow-md mt-4 card"
    >
      <h2 class="text-xl font-semibold">{{ ticket.subject }}</h2>
      <p class="text-gray-700">{{ ticket.description }}</p>
      <p class="text-sm text-gray-500">Criado por: {{ ticket.author.name }}</p>
      <div class="button-container">
        <button
          class="btn"
          @click="ticket.show_conversation = !ticket.show_conversation"
        >
          Mostrar Conversa
        </button>
      </div>

      <div v-if="ticket.show_conversation" class="mt-2">
        <h3 class="font-semibold text-sm">Conversa:</h3>
        <div v-if="ticket.messages.length === 0" class="text-gray-500 text-sm">
          Sem mensagens
        </div>

        <div
          v-for="msg in ticket.messages"
          :key="msg.id"
          class="p-2 border rounded-lg mt-1"
        >
          <p class="text-sm font-medium">{{ msg.author.name }}:</p>
          <p class="text-gray-800">{{ msg.message }}</p>
          <p class="text-xs text-gray-500">
            {{ new Date(msg.created_at).toLocaleString() }}
          </p>
        </div>
        <form class="mt-3" @submit.prevent="sendMessage(ticket.id)">
          <textarea
            class="w-full p-2 border rounded-md"
            placeholder="Escreva uma mensagem..."
            v-model="message[ticket.id]"
          ></textarea>
          <button class="btn">Send</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ref } from 'vue';

export default {
  data: function () {
    return {
      tickets: [],
      logged_user: ref(),
      message: [],
      subject: ref(''),
      description: ref(''),
      showModal: false,
      active_conversations: [], // ids dos tickets cuja conversa está ativa, para ao enviar uma mensagem a conversa continuar a mostra
    };
  },
  methods: {
    async loadData() {
      await this.getLoggedUser();
      await this.getTickets();
    },
    async getLoggedUser() {
      try {
        await this.$store.dispatch('getLoggedUserInfo');
        this.logged_user = this.getLoggedUserInfo;
        console.log('logged user: ', this.logged_user);
      } catch (error) {
        console.log(error);
      }
    },
    async getTickets() {
      this.tickets.forEach((element) => {
        if (element.show_conversation) {
          this.active_conversations.push(element.id);
        }
      });
      try {
        await this.$store.dispatch('getPendingSubjects');
        this.tickets = this.getPendingSubjects;
        this.tickets = this.tickets.map((obj) => ({
          ...obj,
          show_conversation: this.active_conversations.includes(obj.id),
        }));
        console.log(this.tickets);
      } catch (error) {
        console.log(error);
      }
    },
    async addTicket() {
      try {
        const payload = {
          subject: this.subject,
          description: this.description,
        };
        await this.$store.dispatch('insertPendingSubject', payload);
        await this.getTickets();
      } catch (error) {
        console.log(error);
      }
    },
    async sendMessage(ticket_id) {
      try {
        const payload = {
          ticket_id: ticket_id,
          msg: this.message[ticket_id],
        };
        await this.$store.dispatch('insertPendingSubjectsMsg', payload);
        this.message[ticket_id] = '';
        await this.getTickets();
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters([
      'getPendingSubjects',
      'getLoggedUserInfo',
      'insertPendingSubjectsMsg',
      'insertPendingSubject',
    ]),
  },
  // created() {
  //   this.loadData();
  // },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
.btn {
  background-color: rgb(255, 147, 147);
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  max-width: 50%;
}

.button-container {
  display: flex; /* Enable flexbox for buttons */
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Center buttons vertically */
  margin: 10px 0; /* Optional margin to separate from other elements */
}

.btn:hover {
  background-color: rgb(255, 147, 147, 0.658);
}
.container {
  max-width: 50%;
  align-items: center;
}
</style>
